import React from "react";

const HowItWorks = () => {
	return (
		<section id="howItWorks" className="mil-dark-bg">
			<div className="mi-invert">
				<div className="container mil-p-120-0">
					<div className="mil-mb-60">
						<div className="mil-complex-text mil-up mil-mb-15">
							<h3 className="mil-up mil-start">
                SPOLUPRACOVAT <span className="mil-thin">S NÁMI</span> JE JEDNODUCHÉ
							</h3>
						</div>
					</div>
					<div className="row mil-mb-60 ps-4">
						<div className="col-md-7 col-lg-8">
							<p className="mil-mb-20">
								<strong className="mil-h6">1. První konzultace</strong>
								<br></br>
								Řekneme si možnosti a spojíme s vaší představou.
							</p>
							<p className="mil-mb-20">
								<strong className="mil-h6">2. Najdeme ideální byt</strong>
								<br></br>
								Cenově dostupnou a díky rekonstrukci s potenciálem pro zhodnocení.
							</p>
							<p className="mil-mb-20">
								<strong className="mil-h6">3. Financování</strong>
								<br></br>
								Pomůžeme vám využít peníze banky, nikoli své úspory.
							</p>
							<p className="mil-mb-20">
								<strong className="mil-h6">4. Blesková rekonstrukce</strong>
								<br></br>
								Vše zajistíme my, do 4 týdnů od podpisu smlouvy. Přebíráte si svou NEGARSONKU.
							</p>
							<p className="mil-mb-20">
								<strong className="mil-h6">5. Další spolupráce</strong>
								<br></br>
								Rozhodnete se, jak chcete svou <strong className="mil-h6">investici zhodnotit</strong> – my zajistíme hladký průběh.<br></br>
								<strong className="mil-h6">Najdeme nájemníky</strong> – byt začne vydělávat hned.<br></br>
								<strong className="mil-h6">Pomůžeme s prodejem</strong> – pokud chcete zhodnotit investici prodejem, zajistíme kupce.<br></br>
								<strong className="mil-h6">Postaráme se o správu nemovitosti</strong> – řešíme nájemní smlouvy, komunikaci s
								nájemníky i běžnou údržbu.
							</p>
							<p className="mil-mb-30">
								Díky řešení <span className="mil-h5">NEGARSONKA</span> získáte jedinečnou investiční nemovitost. Můžete ji pronajímat
								nebo prodat se ziskem
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HowItWorks;
