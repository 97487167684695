import React from "react";

import Preloader from "./components/Preloader.js";
import Banner from "./components/contentPages/Banner.js";
import Story from "./components/contentPages/Story.js";
import Products from "./components/contentPages/Products.js";
import ContactForm from "./components/contentPages/ContactForm.js";
import Realization from "./components/contentPages/Realization.js";
import Footer from "./components/Footer.js";
import WhyUs from "./components/contentPages/WhyUs.js";
import HowItWorks from "./components/contentPages/HowItWorks.js";
import Myths from "./components/contentPages/Myths.js";
import WhyInvest from "./components/contentPages/WhyInvest.js";
import FAQ from "./components/contentPages/FAQ.js";
import AboutUs from "./components/contentPages/AboutUs.js";

const MainPage = () => {

  return (
    <>
      <Preloader />
      <Banner />
      {/* <Products /> */}
      <WhyUs />
      <HowItWorks />
      <Myths />
      <WhyInvest />
      <FAQ />
      <AboutUs />
      {/* <Story />
      <Realization /> */}
      <ContactForm />
      <Footer />
    </>
  );
};

export default MainPage;