import React from "react";

const WhyUs = () => {
	return (
		<section id="whyUs">
			<div className="mi-invert">
				<div className="container mil-p-120-0">
					<div className="mil-mb-30">
						<div className="mil-complex-text mil-up mil-mb-15">
              <h3 className="mil-up mil-start">
                <span className="mil-thin">MALÝ BYT,</span> VELKÁ PŘÍLEŽITOST
              </h3>
						</div>
					</div>
					<div className="row mil-mb-30">
							<h4 className="mil-up mil-start">
								INVESTICE DO MALÉHO BYTU <span className="mil-thin">NEBYLA NIKDY JEDNODUŠŠÍ</span>
							</h4>
              <br></br>
              <br></br>
							<ol className="mil-mb-20 ps-5">
								<li>Nízké vstupní náklady, rychlá návratnost</li>
								<li>Velká poptávka po pronájmu i koupi</li>
								<li>Maximální využití prostoru díky chytrému designu NEGARSONKY</li>
							</ol>
							<p className="mil-mb-20">
                Standardní garsonky<strong className="mil-h6"> nevyužívají svůj potenciál – NEGARSONKA ano!</strong><br></br>
                Zrekonstruujeme malý prostor na komfortní, moderní a plně funkční byt, který je atraktivní pro nájemníky i kupce.
							</p>
              <h5>VÝSLEDEK</h5>
              <p className="mil-mb-20">
               Ze starého, vybydleného bytu získáte <strong className="mil-h6">vysoce atraktivní nemovitost, </strong>
               která se <strong className="mil-h6">rychle pronajímá, </strong> 
               drží hodnotu a přináší <strong className="mil-h6">stabilní výnos.</strong> 
               <br></br>
               Díky řešení NEGARSONKA máte:
							</p>
							<ul className="mil-mb-20 ps-5">
								<li>Chytrý design inspirovaný světovými trendy</li>
								<li>Skryté úložné prostory, modulární nábytek</li>
								<li>Vzdušný a efektivní prostor s maximální hodnotou</li>
							</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WhyUs;
