import React from 'react';

const Banner = () => {
    return (
        <section className="mil-banner mil-dark-bg" id='banner'>
            <div className="mi-invert-fix">
                <div className="mil-animation-frame">
                    <div className="mil-animation mil-position-1 mil-scale" data-value-1="7" data-value-2="1.6"></div>
                    <div className="mil-animation mil-position-2 mil-scale" data-value-1="4" data-value-2="1"></div>
                    <div className="mil-animation mil-position-3 mil-scale" data-value-1="1.2" data-value-2=".1"></div>
                </div>

                <div className="mil-gradient"></div>

                <div className="container">
                    <div className="mil-banner-content mil-up">

                        <h1 className="mil-muted mil-mb-60">NE<span className="mil-thin">GARSONKA </span><br /></h1>
                        <div className="row">
                            <div className="col-md-7 col-lg-8">
                                <p className="mil-h4 mil-light-soft mil-mb-60">
                                Rekonstrukce a investice na klíč <br></br>
                                Vytvoříme prostor, který pracuje pro vás.
                                </p>
                            </div>
                            {/* skrýt na SE - malý display
                            <div className="col-md-7 col-lg-8 hidden-on-small">
                                <p className="mil-light-soft mil-mb-60">
                                    Zaměřujeme se na vytváření chytrých a stylových vestaveb do maloplošných bytů, které
                                    vašemu domovu dodají více prostoru pro to, co skutečně potřebujete. Ať už je to více
                                    místa na práci, úložného místa nebo jen místo, kde si vychutnáte svou ranní kávu, my vám
                                    takový prostor vytvoříme.
                                </p>
                            </div> */}
                        </div>

                        <a href="#howItWorks" className="mil-button mil-arrow-place mil-btn-space">
                            <span>Jak to funguje</span>
                        </a>

                        <a href="#aboutUs" className="mil-link mil-muted mil-arrow-place ms-5">
                            <span>Kdo jsme</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
