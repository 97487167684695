import React from "react";
import CollapsibleParagraph from "../CollapsibleParagraph.js";

const FAQ = () => {
	return (
		<section id="faq">
			<div className="mi-fix">
				<div className="container mil-p-120-0">
					<div className="mil-mb-60">
						<div className="mil-complex-text mil-up mil-mb-15">
							<h3 className="mil-up mil-start">
								ODPOVĚDI NA OTÁZKY
							</h3>
						</div>
					</div>
					<div className="row mil-mb-60 ps-4">
						<div className="col-12">
							<CollapsibleParagraph title={"1. Kolik peněz potřebuji na začátek?"}
								children={<span>
									Většina klientů financuje nákup hypotékou, potřebujete tedy pouze počáteční vklad.
									Běžně banky poskytují hypoteční úvěry pokrývající 80 % hodnoty nemovitosti, v
									některých případech dokonce až 90 %. To znamená, že při nákupu bytu v hodnotě 6
									milionů korun potřebujete pouze 1,2 milionu, respektive 600 tisíc korun vlastních
									prostředků.
								</span>
								} />
							<CollapsibleParagraph title={"2. Jak dlouho celý proces trvá?"}
								children={<span>
									Celý proces je navržen tak, aby byl co nejrychlejší a nejefektivnější. Od zaměření po
									předání hotové NEGARSONKY uběhnou přibližně 4 týdny, pokud jde o samotnou
									rekonstrukci. Přidáme-li čas na nalezení vhodné nemovitosti a financování, obvykle celý
									proces zabere 2–3 měsíce.
								</span>
								} />
							<CollapsibleParagraph title={"3. Co když nemám zkušenosti s investicemi?"}
								children={<span>
									Nemusíte mít. My se postaráme o výběr bytu i jeho přestavbu, vy pouze rozhodnete o
									financování.
								</span>
								} />
							<CollapsibleParagraph title={"4. Jak probíhá financování investiční nemovitosti?"}
								children={<span>
									Pro financování nemovitosti je nejvýhodnější využít hypoteční úvěr, což umožňuje
									investovat s minimálním vlastním kapitálem. Banka pokryje většinu nákladů, a vy tak
									můžete těžit z finanční páky – vlastníte nemovitost s vyšší hodnotou, než kolik jste do ní
									sami vložili. Díky tomu váš vlastní kapitál efektivněji pracuje a přináší vyšší zhodnocení.
								</span>
								} />
							<CollapsibleParagraph title={"5. Jaká je návratnost této investice?"}
								children={<span>
									Výnos závisí na lokalitě a podmínkách trhu, ale obecně se pohybuje mezi 4–7 % ročně.
									Navíc lze očekávat dlouhodobý růst hodnoty nemovitosti, který může přidat dalších 3–5
									% ročně.
								</span>
								} />
							<CollapsibleParagraph title={"6. Nese NEGARSONKA odpovědnost za výnos?"}
								children={<span>
									Nejsme investiční firma, negarantujeme výnos z prodeje ani pronájmu, ten se odvíjí od
									ekonmické sitace na trhu. Zajišťujeme pouze vyhledání bytu a jeho rekonstrukci takovým
									způsobem, aby byl potenciál zhodnocení maximální.
								</span>
								} />
							<CollapsibleParagraph title={"7. Z kolika bytů si budu moct vybírat?"}
								children={<span>
									díky designu NEGARSONKY se byty snadno a rychle
									pronajímají.
								</span>
								} />
							<CollapsibleParagraph title={"8. Kolik vaše služba stojí?"}
								children={<span>
								</span>
								} />
							<CollapsibleParagraph title={"9. V jakém městě můžu vaši službu využít?"}
								children={<span>
									Primárně v Praze, ale pokud chcete investovat do bytu v jiném městě, ozvěte se nám.
								</span>
								} />
							<CollapsibleParagraph title={"10. Musím se o správu bytu starat sám, nebo nabízíte i služby správy?"}
								children={<span>
									Můžete si vybrat – buď se o správu bytu postaráte sami, nebo se vám o nemovitost
									budeme starat my. Zajistíme kompletní správu včetně hledání nájemníků, vyřizování
									smluv a řešení běžné údržby. To vám umožní investovat bez starostí.
								</span>
								} />
							<a href="#contacts" className="mil-button mil-arrow-place">
                            	<span>Máte další dotaz? Ozvěte se nám.</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default FAQ;
