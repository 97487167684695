import React from "react";

const AboutUs = () => {
	return (
		<section id="aboutUs">
			<div className="mi-invert-fix">
				<div className="container mil-p-120-0 mb-60">
					<div className="mil-mb-60">
						<div className="mil-complex-text justify-content-start mil-up mil-mb-15">
							<div className="mil-h3 mil-up mil-start">
								KDO JSME
							</div>
						</div>
					</div>
					<div className="row mil-mb-20 ps-4">
						<div className="col-md-7 col-lg-8">
							<p className="mil-mb-20">
								Představte si, že chcete investovat do nemovitosti, ale při pohledu na nabídky vás odradí
								nutnost rekonstrukce. Starosti, nejasné náklady, hledání spolehlivých řemeslníků a
								nekonečné posouvání termínů – to všechno dokáže odradit i ty nejodvážnější investory.
								<strong className="mil-h6"> Přesně tak jsme se cítili i my.</strong>
							</p>
							<p className="mil-mb-20">
								Začali jsme přemýšlet, jak bychom mohli investovat do nemovitostí <strong className="mil-h6">chytře, jednoduše a
									efektivně</strong>. Naše první zkušenost s rekonstrukcí nás naučila dvě zásadní věci:
								<br></br>
								<strong className="mil-h6">
									1. Proces rekonstrukce je pro většinu lidí příliš složitý a stresující.
								</strong>
								<br></br>
								<strong className="mil-h6">
									2. S chytrým přístupem lze všechno výrazně zjednodušit a zrychlit.
								</strong>
							</p>
							<p className="mil-mb-20">
								Přišli jsme na to, že klíčem k úspěchu není jen výběr správné nemovitosti, ale také způsob
								její přeměny. Zjistili jsme, že <strong className="mil-h6">garsonky mají obrovský potenciál, pokud se upraví chytře</strong>
								– promyšlená rekonstrukce a efektivní využití prostoru z nich dělá žádané a výnosné
								nemovitosti. Vznikne díky tomu <strong className="mil-h6">NEGARSONKA</strong>
							</p>
							<p className="mil-mb-20">
								A co financování? <strong className="mil-h6">Objevili jsme kouzlo hypotéky. </strong>
								Díky finanční páce <strong className="mil-h6">je možné začít investovat i bez velkého kapitálu</strong> a postupně budovat
								majetek bez nutnosti vázat všechny vlastní úspory.

							</p>
							<p className="mil-mb-20">
								<strong className="mil-h6">Spojili jsme naši vášeň pro efektivitu, finanční know-how a lásku k chytrým řešením</strong> a
								vytvořili koncept NEGARSONKY – investiční byt na klíč.
							</p>							
						</div>
					</div>
					<div className="mil-mb-60 ps-4">
						<div className="mil-complex-text mil-up mil-mb-15">
							<h3 className="mil-h3 mil-up mil-start">
								<span className="mil-thin">NEGARSONKA je chytrá investice bez starostí</span>
							</h3>				
						</div>
						<p className="mil-mb-20">
								Pokud vám dává smysl investovat chytře, <strong className="mil-h6">rádi vás celým procesem provedeme.</strong>
							</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
