import React, { useState } from 'react';

const CollapsibleParagraph = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => setIsOpen(prev => !prev);

  return (
    <div className="mil-mb-30">
      <div
        onClick={toggleCollapse}
        style={{ cursor: 'pointer' }}
        className=" flex items-center justify-between d-flex align-items-center"
      >
        <strong className="mil-h6">{title}</strong>
        {isOpen ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="rgb(0, 168, 181)" viewBox="0 0 24 24">
            <path d="M7 10l5 5 5-5H7z" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="rgb(0, 168, 181)" viewBox="0 0 24 24">
            <path d="M10 17l5-5-5-5v10z" />
          </svg>
        )}
      </div>
      {isOpen && <p className="mil-h5 mil-thin">{children}</p>}
    </div>
  );
};

export default CollapsibleParagraph;
