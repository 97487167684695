import React from "react";

const Myths = () => {
	return (
		<section id="myths">
			<div className="mi-invert">
				<div className="container mil-p-120-0">
					<div className="mil-mb-60">
						<div className="mil-complex-text mil-mb-15">
							<div className="mil-h3 mil-start">
                 MÝTY O INVESTOVÁNÍ DO NEMOVITOSTI
							</div>
						</div>
					</div>
					<div className="row mil-mb-60 ps-4">
						<div className="col-md-7 col-lg-8">
							<p className="mil-mb-20">
								<strong className="mil-h6">"Nemovitost v Praze je pro běžného člověka finančně nedostupná."</strong>
								<br></br>
								Není to pravda! Díky využití principu finanční páky a našemu modelu je vlastní
								investiční byt dostupný i pro vás. Hypotéka v podstatě dělá z běžného člověka
								podnikatele, aniž by musel disponovat geniálním nápadem nebo velkým majetkem.
							</p>
							<p className="mil-mb-20">
								<strong className="mil-h6">"Investice do nemovitostí je složitá."
								</strong>
								<br></br>
								S námi ne. S NEGARSONKOU se nemusíte starat o hledání vhodného bytu,
								rekonstrukci ani řešení s dodavateli – vše zařídíme za vás. Najdeme vám vhodný
								byt, zajistíme její rekonstrukci a usnadníme celý proces. Vy si pouze zajistíte
								financování, a za pár týdnů máte hotovou investiční nemovitost připravenou k
								pronájmu nebo prodeji.
							</p>
							<p className="mil-mb-20">
								<strong className="mil-h6">"Hypotéka je velký závazek."</strong>
								<br></br>
								Naopak, je to způsob, jak nechat peníze banky pracovat pro vás. Na počátku období
								splácení může být rozdíl mezi nájmem a splátkou hypotéky relativně malý, ale díky
								dlouhodobě rostoucím nájmům a přirozenému poklesu reálné hodnoty splátek v
								důsledku inflace se tento rozdíl postupem času zvětšuje.
							</p>
							<p className="mil-mb-20">
								<strong className="mil-h6">"Malé byty nejsou pohodlné ani výnosné."</strong>
								<br></br>
								Malé byty jsou investičním klenotem – nízká pořizovací cena, vysoká poptávka po
								pronájmu a maximální využití prostoru znamenají stabilní a dlouhodobý výnos. Díky
								chytrému designu NEGARSONKY navíc získáte atraktivní nemovitost, která se
								pronajímá rychleji a s minimální fluktuací nájemníků.
							</p>
							<p className="mil-mb-20">
								<strong className="mil-h6">"Lepší je investovat do nemovitosti. Je to výnosnější a krátkodobější."</strong>
								<br></br>
								Investice do nemovitosti skrz hypotéku nabízí stabilitu, dlouhodobý růst hodnoty a
								možnost využití finanční páky, což znamená, že s menším vlastním kapitálem
								můžete ovládat hodnotnější aktivum. Na rozdíl od akcií nemovitost poskytuje
								hmatatelnou hodnotu, možnost pasivního příjmu z pronájmu a ochranu proti inflaci.
								Navíc úrok z hypotéky lze často odečíst z daní, což zlepšuje celkovou návratnost
								investice.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Myths;
