import React from "react";

const WhyInvest = () => {
	return (
		<section id="myths" className="mil-dark-bg">
			<div className="mi-invert">
				<div className="container mil-p-120-0 mil-mb-60">
					<div className="mil-mb-60">
						<div className="mil-complex-text mil-mb-15">
							<div className="mil-h3 mil-start">
								PROČ SI VYBRAT NEGARSONKU?
							</div>
						</div>
					</div>
					<div className="row mil-mb-60 ps-4">
						<div className="col-md-7 col-lg-8">
							<p className="mil-mb-20">
								<strong className="mil-h6">Vlastní byznys za cizí kapitál</strong>
								<br></br>
								Většinu investice pokryje banka, vy jen využíváte její kapitál k vlastnímu zhodnocení.
							</p>
							<p className="mil-mb-20">
							<strong className="mil-h6">Nízké riziko, stabilní výnos</strong>
								<br></br>
								Nemovitosti dlouhodobě rostou na hodnotě a poskytují konzervativní zhodnocení.

							</p>
							<p className="mil-mb-20">
							<strong className="mil-h6">Vyšší návratnost než u akcií</strong>
								<br></br>
								Akcie mohou být volatilní, zatímco nemovitosti poskytují stabilní a dlouhodobý růst.
							</p>
							<p className="mil-mb-20">
							<strong className="mil-h6">Vlastní byznys za cizí kapitál:</strong>
								<br></br>
								1. Růst hodnoty nemovitosti – dlouhodobá jistota, že váš majetek nabývá na hodnotě.<br></br>
								2. Příjem z nájmu – pravidelný cashflow z pronájmu.

							</p>
							<p className="mil-mb-20">
							<strong className="mil-h6">Daňová optimalizace:</strong>
								<br></br>
								Odpisy – snížení daní z příjmů z pronájmu. <br></br>
								Daňové osvobození – možnost osvobození od daně při prodeji po 5 letech.
							</p>
							<p className="mil-mb-20">
							<strong className="mil-h6">Minimální starosti</strong>
								<br></br>
								Neřešíte rekonstrukci, stavební povolení ani řemeslníky, vše zajistíme my.
							</p>
							<p className="mil-mb-20">
							<strong className="mil-h6">Okamžitý zájem nájemníků </strong>
								<br></br>
								Díky designu NEGARSONKY se byty snadno a rychle pronajímají.
							</p>
						</div>
					</div>
					<a href="#contacts" className="mil-button mil-arrow-place mil-btn-space ms-3" style={{ backgroundColor: 'rgb(255, 87, 74)'}}>
						<span>Chci investovat</span>
					</a>
				</div>
			</div>
		</section>
	);
};

export default WhyInvest;
